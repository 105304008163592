export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================
  navigation: {
    // ? == container ==
    '.container': {
      background: 'unset',
      backgroundColor: 'white',
      position: 'static',
      borderBottom: '1px solid lightgrey',
      padding: ['1rem', '', '', '0.5rem'],
      '.services': {
        display: 'none' // ! hide services nav link
      }
    },
    '.containerScrolled': {
      background: 'unset',
      backgroundColor: 'white',
      borderBottom: '1px solid lightgrey',
      '.services': {
        display: 'none' // ! hide services nav link
      }
    },

    // ? == hamburger and logo ==

    '.logo': {
      position: 'static',
      transform: 'unset',
      img: {
        // maxHeight: ['65px', '65px'],
        maxWidth: ['220px', '250px', '270px', '270px', '350px']
      }
    },

    '.logoScrolled': {
      img: {
        maxHeight: ['65px', '65px']
      }
    },

    '.hamburger > div': {
      backgroundColor: 'black'
    },

    // ? == navMenu ==

    '.smallNavMenu': {
      '> div': {
        borderColor: 'black'
      },

      '.navItem a': {
        fontSize: '1rem',
        fontWeight: 'bold'
      }
    },

    '.navMenu': {
      right: 'unset',
      top: '-200vh'
    },
    '.navMenuOpen': {
      backgroundColor: 'background',
      justifyContent: ['flex-start', '', '', 'flex-start'],
      padding: '0rem',
      paddingTop: '8rem',
      padding: '1rem 0rem',
      borderLeft: '1px solid lightgrey',
      top: '-0vh',
      '.navItem': {
        color: 'black',
        borderBottom: '1px solid lightgrey',
        width: '100%',
        padding: '0rem',
        margin: '0rem',
        a: {
          display: 'flex',
          padding: ['1rem 2rem', '', '2rem'],
          fontSize: '0.9rem',
          textTransform: 'capitalize'
        }
      },
      '.navItemDropdown': {
        width: '100%'
      }
    },
    // ! this maybe confusing but the navBlock doesn't have a nav block open class when it gets opened but navMenu does so we use that class
    // ! to determine if the nav menu is open and use the sibling selector to target the nav block when its open or whens its closed
    '.navMenu ~ .navBlock': {
      right: '0rem !important',
      top: '-100vh'
    },

    '.navMenuOpen ~ .navBlock': {
      right: '0rem',
      top: '-0vh'
    },
    // ! ================================

    '.navItemLink svg': { marginLeft: '0.5rem' },

    // ? == dropdowns ==

    '.navItemDropdownMenu': {
      backgroundColor: 'background',
      opacity: '0.8',
      '.navItem': {
        padding: '0rem',
        a: {
          fontSize: '0.8rem',
          padding: '1rem',
          paddingLeft: '2.5rem'
        }
      }
    },

    // ? == widget ==
    '.socialContainer, .phoneContainer': {
      color: 'black',
      borderColor: 'black',
      margin: '0rem',
      padding: ['1rem 2rem', '', '2rem'],
      a: {
        fontSize: '0.9rem',
        fontWeight: 'normal',
        color: '#5a5a5a'
      },
      svg: {
        marginRight: '1rem',
        width: '18px',
        height: '18px',
        path: {
          fill: '#5a5a5a'
        }
      }
    }
  },

  footer: {
    backgroundColor: 'black',
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5rem',
    paddingBottom: ['3rem', '', '0.5rem'],
    // marginTop: ['', '', '2rem'],
    '.gonationLogo': {
      filter: 'brightness(0) invert(1)',
      maxHeight: '0.9rem',
      margin: ['0.25rem', '0.25rem 0.5rem'],
      svg: {
        height: '1rem'
      }
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'white',
      margin: ['0.25rem', '0.25rem 0.5rem'],
      fontSize: '0.8rem'
    },
    '.multiButtonContainer, .image, .socialContainer  ': {
      display: 'none'
    },

    '.contactDetails-container': {
      display: 'flex',
      color: 'white',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
      svg: {
        marginRight: '0.25rem'
      },
      '.text': {
        fontSize: '0.8rem',
        display: 'flex',
        flexWrap: 'wrap',

        color: 'white',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        a: {
          margin: ['0.25rem', '', '0rem 0.5rem'],
          display: 'flex',
          flexWrap: 'no-wrap',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ===  Reuseables  ===
  // ? ====================

  title: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '2.5rem',
    order: '2',
    color: 'primary',
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
    textTransform: 'uppercase',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'dark',
    fontWeight: 'bold',
    order: '1',
    fontFamily: 'body',
    fontSize: '0.95rem',
    textTransform: 'uppercase'
  },

  text: {
    lineHeight: '1.75',
    marginBottom: '1rem',
    order: '3',
    fontSize: '0.85rem',
    color: '#a2a2a2',
    p: {
      color: '#a2a2a2'
    }
  },

  sideBySide1: {
    marginBottom: '2rem',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      order: ['', '', '1'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      order: ['', '', '2'],
      img: {
        height: '75vh'
      }
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      img: {
        height: '75vh'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: ['', '', 'auto', '', 'calc(100vh - 122px)'],
    display: ['block', '', '', '', 'flex'],
    justifyContent: 'flex-start',
    alignItems: ['flex-start', '', '', '', 'center'],
    marginBottom: ['0rem', '', '1rem', '1rem', '5rem'],
    '.logoHero': {
      display: ['none', '', '', '', 'block']
    },
    '.slick-slider': {
      width: ['100%', '100%', '100%', '100%', '50%'],
      order: ['', '', '', '', '2'],
      height: ['', '', '', '', 'calc(100vh - 122px)'],
      '.slick-list': {
        borderRadius: '0px 0px 0px 50px'
      },
      '.slick-prev, .slick-next': {
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'secondary',
        height: '30px',
        borderRadius: '100px',
        padding: '5px'
      },
      '.slick-prev': {
        left: '1rem'
      },
      '.slick-next': {
        left: '3rem'
      },
      '.slick-dots': {
        left: '1rem',
        right: 'unset',
        display: 'none'
      },
      '.slick-dots li button:before': {
        color: 'white',
        display: 'none'
      },
      '.slick-slide img': {
        height: ['', '', '', '', 'calc(100vh - 122px)']
      }
    },
    '.hero_content_container': {
      // marginBottom: ['', '', '', '7rem'],
      alignSelf: 'flex-start',
      width: ['100%', '100%', '100%', '100%', '50%'],
      padding: ['2rem', '', '', '', '2rem 2rem'],

      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text, span': {
        variant: 'customVariants.text',
        color: 'dark',
        p: {
          fontSize: '0.9rem',
          color: 'dark'
        }
      },
      a: {
        order: '4',
        alignSelf: 'unset',
        '.ctaButton': {
          variant: 'buttons.primary'
        }
      }
    }
  },
  homepageHeroShout: {
    // display: 'none',
    backgroundColor: ['transparent', 'transparent', 'transparent'],
    maxWidth: 'unset',
    left: ['0rem', '0rem', '0rem', '0rem'],
    bottom: ['', '', '', '', '0rem'],
    width: ['100%', '', '', '', '40%'],
    position: ['', '', '', 'static', 'absolute'],
    transform: ['', '', '', 'unset', ''],
    '.title': {
      // variant: 'customVariants.title',
      fontSize: ['1.25rem', '', '1.5rem'],
      order: 'unset',
      filter: 'brightness(0)', // !changes the border and text color
      marginBottom: '0.5rem'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide2'
  },
  homepageRetail: {
    variant: 'customVariants.sideBySide1'
  },
  homepageWholesale: {
    variant: 'customVariants.sideBySide2'
  },
  homepageSpecials: {
    variant: 'customVariants.sideBySide1'
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '', '4rem 1rem'],
    maxWidth: 'unset',
    backgroundPosition: 'left',
    backgroundSize: '15%',
    backgroundRepeat: 'no-repeat',

    '.menuSectionTitle': {
      margin: '1rem 0rem',
      fontSize: ['2rem', '', '', '3rem'],
      fontWeight: 800,
      textAlign: 'center',
      color: 'primary',
      borderColor: 'primary',
      paddingRight: '2rem',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      padding: '1rem 2rem',
      width: ['100%', '', '100%']
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      fontSize: ['1rem', '1.25rem', '1.5rem'],
      opacity: '0.6',
      textAlign: 'center',
      margin: ['1.5rem', '', '4rem 2rem 2rem']
    },
    '.menuItemContainer': {
      maxHeight: '450px',
      padding: '0.5rem',
      overflowY: 'scroll',
      MsOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    },

    '.menuItemNameContainer': {
      textDecoration: 'underline',
      margin: '1rem'
    },

    '.menuItemDescription': {
      color: 'secondary',
      fontSize: ['0.9rem', '1.15rem', ''],
      textAlign: 'left',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem 0rem 0rem 2rem', '', '0rem 1rem 0rem 2rem']
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    },
    '.slick-prev, .slick-next': {
      color: 'white',
      bottom: '0rem',
      backgroundColor: 'secondary',
      borderRadius: '100px',
      padding: '5px'
    },

    '.slick-slider .slick-arrow': {
      height: '30px',
      width: '30px',
      color: 'white'
    }
  },

  homepageLocation: {
    padding: ['6rem 1rem', '', '10rem 1rem'],
    color: 'white',
    '.title': {
      variant: 'customVariants.title',
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white'
    },
    a: {
      order: '4',
      alignSelf: 'unset',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    maxWidth: '1200px',
    margin: '0 auto',
    '.title': {
      variant: 'customVariants.title',
      order: 'unset'
    }
  },

  // ? ========================
  // ? =====  Menu pages  =====
  // ? ========================

  menu: {
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.menuSectionTitle': {
      borderBottom: '2px solid',
      borderColor: 'secondary',
      // width: 'fit-content',
      fontSize: '1.1rem',
      textTransform: 'uppercase',
      textAlign: 'left',
      margin: ['0rem 0.5rem 0.5rem', '', '0 1rem 1rem'],
      paddingBottom: '0rem',
      width: ['calc(100% -2rem)']
    },

    '.menuSectionDescription': {
      fontSize: '0.95rem',
      textAlign: 'left',
      opacity: '0.7',
      lineHeight: '1.5',
      margin: ['0rem 0.5rem 1rem', '', '0 1rem 1rem'],
      width: ['calc(100% -2rem)']
    },

    '.menuItemContainer , .menuItemContainerImgActive': {
      margin: '0rem 0rem 0rem 0rem',
      width: ['', '50%', '33.3%']
    },
    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      flexGrow: 'unset',
      margin: '0rem'
    },
    '.menuItemName': {
      fontSize: '0.8rem',
      margin: '0rem',
      padding: '0rem',
      color: 'primary'
    },
    '.menuItemDescription': {
      marginTop: '0rem',
      fontSize: '0.8rem',
      lineHeight: '1.5',
      width: '100%',
      color: '#dc1817'
    },

    '.itemVariantsContainer': {
      fontSize: '0.8rem'
    },

    '.variantContainer': {
      margin: '0rem',
      fontSize: '0.8rem'
    },

    '.menuItemPriceLabel': {
      fontSize: '0.8rem',
      '::after': {
        content: "' -'"
      }
    },
    '.menuItemPriceVariants': {
      fontWeight: 'bold',
      fontSize: '0.8rem'
    },
    '.menuItemPrice': {
      fontSize: '0.8rem',
      fontWeight: 'bold'
    }
  },

  menuExpectations: {
    variant: 'customVariants.menuStyles',
    '.menuItemContainer, .menuItemContainerImgActive': {
      width: '100%'
    }
  },
  menuPayment: {
    variant: 'customVariants.menuStyles',
    '.menuItemContainer, .menuItemContainerImgActive': {
      width: '100%'
    }
  },
  menuNutrition: {
    variant: 'customVariants.menuStyles'
  },
  menuMassage: {
    variant: 'customVariants.menuStyles'
  },
  menuForms: {
    variant: 'customVariants.menuStyles',
    '.menuItemContainer, .menuItemContainerImgActive': {
      width: '100%'
    },
    paddingBottom: ['0rem', '0rem', '0rem', '0rem'],
    '.menuContainer': {
      paddingBottom: '0rem'
    }
  },
  menuLaser: {
    variant: 'customVariants.menuStyles',
    '.menuItemContainer, .menuItemContainerImgActive': {
      width: '100%'
    }
  },
  menuChiropractic: {
    variant: 'customVariants.menuStyles'
  },
  menuAcupuncture: {
    variant: 'customVariants.menuStyles'
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    // '.albumsCTA ': {
    //   variant: 'buttons.secondary',
    // },
    '.albumsContainer': {
      display: 'none'
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  contactForm: {
    padding: '4rem 1rem',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary'
        // borderRadius: '0px',
      }
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  wholesale: {
    background: 'unset'
  }
}
